// 3rd party
import { useState, useEffect } from 'react'
import Link from 'next/link'
import dynamic from 'next/dynamic'
import { getModalData } from '@/lib/contentful/modal'

// Helpers
import { formatUrlSlug, validURL } from '@/lib/helper'
import { enableBodyScroll } from '@/lib/helper'

// Components
import Icon from '@/components/Icon/Icon'

//gta
import { ctaClick } from '@/lib/ga-events'

const DynamicVideoPlayer = dynamic(() => import('@/components/VideoPlayer'), {
  loading: () => null,
  ssr: false,
})

const DynamicModal = dynamic(() => import('@/components/Modal/Modal'), {
  loading: () => null,
  ssr: false,
})

const CTA = ({
  className,
  inlineStyles,
  label,
  openInNewTab = false,
  openVideoPlayer = false,
  scroll = true,
  url,
  background,
}) => {
  const [isVideoPlayerOpen, setIsVideoPlayerOpen] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const [modalData, setModalData] = useState(null)

  const handlePlayClick = (open) => {
    enableBodyScroll(!open)
    setIsVideoPlayerOpen(open)
  }

  const formattedUrl = formatUrlSlug(url)

  useEffect(() => {
    let isMounted = true

    if (url?.startsWith('#')) {
      getModalData(url).then((response) => {
        if (response?.success && isMounted) {
          setModalData(response?.data)
        }
      })
    }

    return () => {
      isMounted = false
    }
  }, [])

  return (
    <>
      {openVideoPlayer ? (
        <>
          <button className={`${className} button-bg`} onClick={() => handlePlayClick(true)}>
            <span className="inline-flex justify-center items-center gap-2">
              {label}
              <Icon viewBox="2 2 20 20" size={16} icon={'play'} />
            </span>
          </button>
          <DynamicVideoPlayer
            isOpen={isVideoPlayerOpen}
            closeCallback={() => handlePlayClick(false)}
            url={url}
          />
        </>
      ) : validURL(url) ? (
        <a
          className={`${className} ${className.includes('btn') && 'button-bg'}`}
          href={formattedUrl}
          rel={openInNewTab ? 'noopener noreferrer' : ''}
          target={openInNewTab ? '_blank' : '_self'}
          onClick={() => ctaClick(formattedUrl, label)}
        >
          {label}
        </a>
      ) : (
        <>
          {url?.startsWith('#') ? (
            <>
              <button
                className={`${className} button-bg`}
                onClick={() => {
                  if (modalData) {
                    setOpenModal(true)
                  } else {
                    const targetElement = document.querySelector(url)
                    if (targetElement) {
                      targetElement.scrollIntoView({ block: 'start' })
                    }
                  }
                }}
              >
                <span className="inline-flex justify-center items-center gap-2">{label}</span>
              </button>
              {modalData && (
                <DynamicModal {...modalData} isOpen={openModal} setIsOpen={setOpenModal} />
              )}
            </>
          ) : (
            <Link href={formattedUrl} passHref scroll={scroll}>
              <a
                className={`${className} ${className.includes('btn') && 'button-bg'}`}
                target={openInNewTab ? '_blank' : '_self'}
                onClick={() => ctaClick(formattedUrl, label)}
              >
                {label}
              </a>
            </Link>
          )}
        </>
      )}

      <style jsx>{`
        .button-bg {
          background-color: ${background && background == 'Gray'
            ? '#97A3AE'
            : background && background == 'Orange'
            ? '#DD5F13'
            : 'white'};
          border: 1px solid
            ${background && background == 'Gray'
              ? '#97A3AE'
              : background && background == 'Orange'
              ? '#DD5F13'
              : 'black'};
          border-radius: 2px;
        }
        ${inlineStyles}
      `}</style>
    </>
  )
}

export default CTA
